import React from "react"
import Layout from "../components/layout"

const About = () => {
  return   <Layout>
    <section id="mainTitle" style={{background: 'white', color: 'black'}}>
      <div className="row">
        <div className="twelve columns">
          <br />
          <h1 className="responsive-headline"  style={{color: 'black'}}>ABOUT COOKIES</h1>
          <br />
          
          <div>
            <p>
              Cookies are small text files that most websites store in user devices that access the Internet in order to identify the individual devices that users used in accessing. Their storage is under the complete control of a browser used by the user - he can limit or disable cookie storage as desired.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>Why are cookies needed?</h2>
          </div>
          <div>
            <p>
              Cookies are essential for providing user-friendly online services. The most common e-commerce functions would not be possible without cookies. The interaction between the web user and the website is quicker and easier with the help of cookies. With their help, the website remembers the individual's preferences and experiences, thus saving time, making web browsing more effective and enjoyable. Cookies are used by most websites; they are a convenient means of keeping fresh and relevant content that is in accordance with the interests and preferences of each web user. There are many reasons for the use of cookies - first of all, their ability to store information about the status of a particular web page (details of the customization of a particular website), help in the implementation of various online services (logging in to events, logging into Ineor services), help in providing various statistics about the habits of a web user - as the frequency of visiting a particular web site can only be monitored by them. By using cookies, Ineor can evaluate the effectiveness of the design of its websites.
            </p>
            <p>
              With cookies, Ineor collects the following information from website visitors:
              <ol>
                <li>
                  device information (type, brand and type);
                </li>
                <li>
                  information about the operating system (type and version);
                </li>
                <li>
                  web browser information (type and version);
                </li>
                <li>
                  information on screen settings (eg resolution);
                </li>
                <li>
                  information about language settings;
                </li>
                <li>
                  information about pages that were displayed.
                </li>
              </ol>
            </p>
            <p>
              We collect technical information about the device and the software in order to ensure the quality and linguistic display of web pages. Information about the pages that have been displayed is collected for the needs of analytics, which enable us to improve content and user experience.
            </p>
            <p>
              Using cookies, in addition to the above information collected by website visitors, Ineor also collects the following personal information:
              <ol>
                <li>
                  IP address;
                </li>
                <li>
                  location or access point of access to the Internet.
                </li>
              </ol>
            </p>
            <p>
              Ineor collects this information for user identification purposes.
            </p>
          </div>
          <div>
            <h2 className="responsive-headline" style={{color: 'black'}}>The Website contains the following cookies</h2>
          </div>
          <div>
           <p>
             <table className="about-cookies">
               <thead>
               <tr>
                 <th id="th-type" style={{width: "50%;"}}>Type of cookie</th>
                 <th id="th-administrator" style={{width: "20%;"}}>To whom they serve</th>
                 <th id="th-name-n-duration" style={{width: "30%;"}}>Name and duration</th>
               </tr>
               </thead>
               <tbody>
               <tr>
                 <td headers="th-type">
                   <p>
                     <strong>Urgently needed cookies</strong><br />
                     The cookie uses a security system to prevent brute-force attacks and does not allow the user to
                     identify.
                   </p>
                 </td>
                 <td headers="th-administrator">
                   <p>Ineor d.o.o.</p>
                 </td>
                 <td headers="th-name-n-duration">
                   <p>
                     <code>PH_HPXY_CHECK</code>
                     - until the session is closed.
                   </p>
                 </td>
               </tr>
               <tr>
                 <td headers="th-type">
                   <p>
                     <strong>Urgently needed cookies</strong><br />
                     These cookies are required to provide you with the services available on this site. They allow you
                     to log in to your user profile, language selection, currency, agree to terms and identify the
                     user's session.
                   </p>
                 </td>
                 <td headers="th-administrator">
                   <p>Ineor d.o.o.</p>
                 </td>
                 <td headers="th-name-n-duration">
                   <p>
                     <code>cookies_ok</code>
                     – 12 months.
                   </p>
                 </td>
               </tr>
               <tr>
                 <td headers="th-type">
                   <p>
                     <strong>Cookies for analytics</strong><br />
                     These cookies help us understand how our visitors use our site. With the help of them, we improve
                     the user experience and determine the requirements and trends of users. We only use these cookies
                     if you expressly consent to this.
                   </p>
                 </td>
                 <td headers="th-administrator">
                   <p>Ineor d.o.o.</p>
                 </td>
                 <td headers="th-name-n-duration">
                   <p>
                     <code>_pk_id*</code>
                     – 13 months,<br/>
                     <code>_pk_ref*</code>
                     – 6 months,<br />
                     <code>_pk_ses*, _pk_cvar* &amp; _pk_hsr*</code>
                     – 30 minutes.
                   </p>
                 </td>
               </tr>
               </tbody>
             </table>
           </p>
            <p>
              For more information on cookies usage in Ineor pages send e-mail to i(at)ineor.si.
            </p>
          </div>

        </div>
      </div>
    </section>
  </Layout>
};

export default About; 